<template>
    <div class="test-timer">
        <div class="test-end__title">
            <div class="test-end__pic">
                <img class="test-end__img" src="~assets/img/favicons/bad-icon.svg" alt="" width="55" height="55">
            </div>
            <span>Тестирование не пройдено!</span>
        </div>

        <div
            v-if="test.answer_correct > 0 && test.questions_count > 0"
            class="test-end__count-block"
        >
            <div class="test-end__section ml-0">
                <span class="test-end__count">{{ test.answer_correct }}</span>
                <span class="test-end__lore">
                    {{ functions.num2str(test.answer_correct, phrases.correct) }}
                    {{ functions.num2str(test.answer_correct, phrases.answer) }}
                    из
                    {{test.questions_count}}
                </span>
            </div>
        </div>

        <ul
            v-if="test.material_list"
            class="test-repeat__list"
        >
            <li v-for="material in test.material_list" :key="`material_${material.id}`" class="test-repeat__item">
                <router-link tag="a" :to="`/knowledge/article/${material.id}/`" class="test-repeat__article">
                    {{material.title}}
                </router-link>
            </li>
        </ul>

        <button
            class="v-btn"
            @click="$router.back()"
        >
            Вернуться назад
        </button>
    </div>
</template>

<script>
    import session from '@/api/session';
    import functions from 'assets/js/functions';

    export default {
        name: 'Repeat',

        data() {
            return {
                functions,
                phrases: {
                    correct: ['правильный', 'правильных', 'правильных'],
                    answer: ['ответ', 'ответа', 'ответов']
                },
                test: {}
            };
        },
        methods: {
            async loadData() {
                let request = {};

                if (!this.$route.query.type || this.$route.query.type === 'material') {
                    request = await session.get('/api/v1/testing/end/');

                    this.test = request.data;

                    if (this.test.material_list.length) {
                        this.test.material_list.map(material =>
                            this.$store.dispatch('knowledge_base/editMaterial', {
                                id: material.id,
                                required: true
                            })
                        );
                    }
                }
            }
        },
        async created() {
            if (this.$route.query.type && this.$route.query.type === 'document') {
                this.test.answer_correct = 0;
                this.test.questions_count = 0;
            } else {
                await this.loadData();
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "stages";
</style>
